import React, { useState } from "react";
import TaxExemptionFormPage from "./TaxExemptionFormPage";
import AccountDetailsPage from "./AccountDetailsPage";
import BillingAndShippingDetailsPage from "./BillingAndShippingDetailsPage";
import { useSelector } from "react-redux";
import { useGetAccountDetailsByIdQuery } from "features/baseApi";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button } from "@mui/material";

const NavToTaxExemptionFormAlert = ({ isTaxExempt, page }) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(true);

  if (
    !showAlert ||
    isTaxExempt ||
    isTaxExempt === null ||
    page === "tax-exemption-certificates"
  )
    return null;

  return (
    <div className="w-[95%] mb-[16px]">
      <Alert
        severity="info"
        sx={{
          "& .MuiAlert-action": {
            marginLeft: "24px",
            marginBottom: "2px",
          },
        }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
              sx={{ position: "absolute", left: 8 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <Button
              color="inherit"
              size="small"
              className="text-sm bg-lighterblueblue hover:bg-lighterblueblue/80 text-dgiWhite"
              onClick={() => navigate("/settings/tax-exemption-certificates")}
            >
              Upload
            </Button>
          </>
        }
        className="relative text-base flex items-center justify-center pl-10"
      >
        Looking to upload a tax exemption certificate?
      </Alert>
    </div>
  );
};

function PageWrapper({ page }) {
  const userDetails = useSelector((state) => state.user.user);
  const stripeCustomerId = userDetails?.live_stripe_customer_id;
  const { data } = useGetAccountDetailsByIdQuery(userDetails?.UserId);

  let isTaxExempt = null;
  if (data?.is_tax_exempt) {
    isTaxExempt = true;
  } else if (data?.is_tax_exempt === false) {
    isTaxExempt = false;
  }

  let childComponent;
  switch (page) {
    case "account-details":
      childComponent = <AccountDetailsPage userDetails={userDetails} />;
      break;
    case "billing-shipping-details":
      childComponent = (
        <BillingAndShippingDetailsPage userDetails={userDetails} />
      );
      break;
    case "tax-exemption-certificates":
      childComponent = (
        <TaxExemptionFormPage
          isTaxExempt={isTaxExempt}
          stripeCustomerId={stripeCustomerId}
          userDetails={userDetails}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="w-full flex flex-col items-center max-w-[1250px]">
      <NavToTaxExemptionFormAlert isTaxExempt={isTaxExempt} page={page} />
      {childComponent}
    </div>
  );
}

export default PageWrapper;
