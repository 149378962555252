import React, { useRef, useEffect, useState } from "react";
import { Box, Skeleton, Alert, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";

function ExemptTaxIframe({
  stripeCustomerId,
  stripeCustomerEmail,
  exemptTaxCompanyId,
}) {
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener("load", handleLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener("load", handleLoad);
      }
    };
  }, []);

  const iframeSrc = `https://app.exemptax.com/ecommerce-drop?${new URLSearchParams(
    {
      integration_type: "stripe",
      company_id: exemptTaxCompanyId,
      customer_id: stripeCustomerId,
      email: stripeCustomerEmail,
    }
  ).toString()}`;

  return (
    <Box className="w-full flex flex-col items-center relative">
      <iframe
        ref={iframeRef}
        src={iframeSrc}
        style={{
          width: "100%",
          maxWidth: "1100px",
          height: "700px",
          // borderWidth: "thin",
          border: "1px solid rgb(2 129 192)",
          borderRadius: "4px",
          display: isLoading ? "none" : "block",
        }}
        title="ExempTax Portal"
      />

      {isLoading && (
        <Skeleton
          variant="rectangular"
          height={700}
          sx={{
            position: "absolute",
            width: "100%",
            maxWidth: "1100px",
            borderRadius: 1,
            bgcolor: "grey.100",
          }}
        />
      )}
    </Box>
  );
}

function TaxExemptionFormPage({ isTaxExempt, stripeCustomerId, userDetails }) {
  const stripeCustomerEmail = userDetails?.email;
  const exemptTaxCompanyId = process.env.REACT_APP_EXEMPTAX_COMPANY_ID;

  if (!exemptTaxCompanyId || !stripeCustomerId || !stripeCustomerEmail) {
    return (
      <Box className="w-full flex justify-center p-4">
        <div className="text-red-500">
          Missing required configuration or user details
        </div>
      </Box>
    );
  }

  return (
    <Box className="w-full flex flex-col gap-[16px] py-[1.5rem] mb-[5rem] px-[14px]">
      {isTaxExempt !== null && (
        <>
          <Alert
            severity={isTaxExempt ? "success" : "warning"}
            sx={{ maxWidth: "1100px", mx: "auto", width: "100%", mb: 2.25 }}
            className={`text-base flex ${
              isTaxExempt ? "items-center" : "items-start"
            }`}
          >
            {isTaxExempt ? (
              "You currently have an active tax exemption certificate. You can apply for a new certificate below if you need to."
            ) : (
              <>
                Looks like you haven't uploaded a tax exemption certificate yet!
                <div className="mt-[4px]">
                  Once you upload your certificate, you'll receive an email
                  within 24 hours about your exemption status.
                </div>
              </>
            )}
          </Alert>
        </>
      )}

      <Box
        className="text-wolfGrey flex flex-col gap-[8px] mb-[12px]"
        sx={{ maxWidth: "1100px", mx: "auto", width: "100%", px: 1 }}
      >
        <Typography variant="body2" className="font-bold text-base">
          Why do I need to upload my tax exemption certificate?
        </Typography>
        <Typography variant="body2" className="text-base">
          You need to upload your tax exemption certificate to purchase items
          from DGI Direct vendors. You will only need to do this once.
        </Typography>
      </Box>

      <ExemptTaxIframe
        stripeCustomerId={stripeCustomerId}
        stripeCustomerEmail={stripeCustomerEmail}
        exemptTaxCompanyId={exemptTaxCompanyId}
      />
    </Box>
  );
}

export default TaxExemptionFormPage;
