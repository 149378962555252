import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Container,
  Typography,
} from "@mui/material";

function BillingAndShippingDetailsPage({ userDetails }) {
  const shippingAddresses = userDetails?.shipping_addresses;
  const billingOptions = userDetails?.billing_options;

  return (
    <Container className="max-w-[850px]" sx={{ mx: 4, my: 2 }}>
      <div>
        <Typography
          variant="h5"
          sx={{ mb: 1.5, px: "4px" }}
          className="text-wolfGrey"
        >
          Saved Shipping Addresses
        </Typography>
        <TableContainer
          component={Paper}
          elevation={2}
          sx={{
            border: "1px solid rgba(103, 111, 120, 0.3)", // typeGrey with 0.3 opacity
            borderBottom: "none",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="shipping addresses table">
            <TableBody>
              {shippingAddresses?.map((address, index) => (
                <TableRow key={index} className="bg-[#fff]">
                  <TableCell className="text-base">
                    <div className="font-medium text-lg mb-2">
                      {address.name}
                    </div>
                    <div className="text-gray-600">
                      {address.street1}
                      {address.street2 && (
                        <span className="text-base">, {address.street2}</span>
                      )}
                    </div>
                    <div className="text-gray-600">
                      {address.city}, {address.state} {address.zip} -{" "}
                      {address.country}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      
      <div className="mt-[48px]">
        <Typography
          variant="h5"
          sx={{ mb: 1.5, px: "4px" }}
          className="text-wolfGrey"
        >
          Saved Billing Options
        </Typography>
        {billingOptions?.length > 0 ? (
          <TableContainer
            component={Paper}
            elevation={2}
            sx={{
              border: "1px solid rgba(103, 111, 120, 0.3)",
              borderBottom: "none",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="billing options table">
              <TableBody>
                {billingOptions.map((option, index) => (
                  <TableRow key={index} className="bg-[#fff]">
                    <TableCell className="text-base">
                      <div className="font-medium text-lg mb-2">
                        {option.card_brand}
                      </div>
                      <div className="text-gray-600">
                        {option.card_number}
                      </div>
                      <div className="text-gray-600">
                        Expiry: {option.expiry_month}/{option.expiry_year}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1" className="px-[6px]">
            No billing options saved yet.
          </Typography>
        )}
      </div>
    </Container>
  );
}

export default BillingAndShippingDetailsPage;
