import React from "react";
import AccountSettingsSideBar from "./AccountSettingsSidebar";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import PageWrapper from "./PageWrapper";

function AccountSettings() {
  const { page } = useParams();
  
  return (
    <div className="flex flex-col justify-start lg:flex-row lg:justify-center min-h-[100vh] w-full">
      <div className="lg:hidden px-4 py-2">
        <AccountSettingsSideBar page={page} isMobile={true} />
      </div>
      <div className="hidden lg:block">
        <AccountSettingsSideBar page={page} isMobile={false} />
      </div>
      <Divider orientation="vertical" className="h-[auto] hidden lg:block" />
      <PageWrapper page={page} />
    </div>
  );
}

export default AccountSettings;
