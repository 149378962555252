import {
  typesenseProductsIndexName,
  searchClient,
  typesenseInstantsearchAdapter,
} from "App";
import { useDataContext } from "App";
import vendors from "constants/vendors";
import { history } from "instantsearch.js/es/lib/routers";

export const createShopSpecificRouting = () => {
  // The history() function comes from a routing library and provides basic routing functionality
  const originalRouter = history();
  let onUpdateCallback = () => {};

  const router = {
    ...originalRouter,

    // Original read(): Retrieves the current route state (e.g., path, query parameters)
    read() {
      if (!window.location.pathname.startsWith("/shop")) {
        return {};
      }
      // Otherwise, use the original router's read method
      return originalRouter.read();
    },

    // Original write(routeState): Updates the current route based on the provided state
    write(routeState) {
      // If the current path doesn't start with "/shop", do nothing
      if (!window.location.pathname.startsWith("/shop")) {
        return;
      }
      // Otherwise, use the original router's write method
      originalRouter.write(routeState);
    },

    // Original createURL(routeState): Generates a URL string based on the provided route state
    createURL(routeState) {
      if (!window.location.pathname.startsWith("/shop")) {
        return window.location.href;
      }
      return originalRouter.createURL(routeState);
    },

    // Original onUpdate(callback): Sets up a listener for route changes
    onUpdate(callback) {
      onUpdateCallback = callback;
      window.addEventListener("popstate", () => {
        if (window.location.pathname.startsWith("/shop")) {
          callback(router.read());
        }
      });
    },

    // Original dispose(): Cleans up any listeners or resources used by the router
    dispose() {
      originalRouter.dispose();
      window.removeEventListener("popstate", onUpdateCallback);
    },
  };

  // Return an object containing the custom router and state mapping functions
  return {
    router,
    stateMapping: {
      // Convert UI state to route state
      stateToRoute(uiState) {
        const indexUiState = uiState[typesenseProductsIndexName] || {};
        return {
          query: indexUiState.query,
          page: indexUiState.page,
          refinementList: indexUiState.refinementList,
          sortBy: indexUiState.sortBy,
        };
      },
      // Convert route state to UI state
      routeToState(routeState) {
        return {
          [typesenseProductsIndexName]: {
            query: routeState.query,
            page: routeState.page,
            refinementList: routeState.refinementList,
            sortBy: routeState.sortBy,
          },
        };
      },
    },
  };
};

export function getDefaultFilters(connectedVendorCodes) {
  const connectedVendorSearchFilters = Object.entries(connectedVendorCodes)
    .filter((vendor) => {
      return vendor[1] && vendors[vendor[0]];
    })
    .map((vendor) => {
      return vendors[vendor[0]].search_filter;
    });
  const defaultFilters =
    connectedVendorSearchFilters.length === 0
      ? "distributors: [Alphabroder, AS Colour] || direct:=true"
      : `distributors:[${connectedVendorSearchFilters}, AS Colour] || direct:=true`;
  return defaultFilters;
}

export const createSearchConfig = (adapter, indexName, defaultFilters) => {
  return {
    getSearchParams: (query, customParams = {}) => [
      {
        indexName,
        params: {
          ...adapter.configuration.additionalSearchParameters,
          query,
          facets: [
            "colors.name",
            "distributors",
            "master_brand",
            "master_category_tags",
            "master_size_list",
          ],
          filters: defaultFilters,
          highlightPostTag: "__/ais-highlight__",
          highlightPreTag: "__ais-highlight__",
          maxValuesPerFacet: 200,
          page: 0,
          ...customParams,
        },
      },
    ],
  };
};

function useSearchConfig() {
  const { connectedVendorCodes } = useDataContext();
  const defaultFilters = getDefaultFilters(connectedVendorCodes);
  const searchConfig = createSearchConfig(
    typesenseInstantsearchAdapter,
    typesenseProductsIndexName,
    defaultFilters
  );

  const performSearch = async (query) => {
    const searchRequest = searchConfig.getSearchParams(query);
    return await searchClient.search(searchRequest);
  };

  return { performSearch };
}

export default useSearchConfig;
