import React from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";

const AccountDetailsPage = ({ userDetails }) => {
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";

    // Remove all non-digits
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Check if it's a US number with country code
    if (cleaned.length === 11 && cleaned.startsWith("1")) {
      const match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
      }
    }

    // Handle regular 10-digit number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return original if format doesn't match
  };

  const DetailRow = ({ label, value, width }) => (
    <Grid container spacing={0} sx={{ mb: 2 }} item xs={12} sm={width || 12}>
      <Grid item xs={12} sm={width ? 4 : 3} sx={{ pr: 1 }}>
        <Typography variant="subtitle1" className="font-bold text-sm">
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={width ? 8 : 9}>
        <Typography variant="body1" className="text-sm">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  const personalDetailsArray = [
    { label: "Shop Name", value: userDetails?.["custom:shop_name"] },
    {
      label: "Contact Name",
      value: `${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`.trim(),
      halfWidth: true
    },
    { 
      label: "Contact Role", 
      value: userDetails?.role,
      halfWidth: true 
    },
    { label: "Phone", value: formatPhoneNumber(userDetails?.phone_number) },
    { label: "Email", value: userDetails?.email },
  ];

  const addressDetailsArray = [
    { 
      label: "Mailing Address", 
      value: userDetails?.suite 
        ? `${userDetails?.street1}, ${userDetails?.suite}`
        : userDetails?.street1
    },
    { 
      label: "City", 
      value: userDetails?.city
    },
    { 
      label: "State", 
      value: userDetails?.state
    },
    { 
      label: "Zip Code", 
      value: userDetails?.zip
    },
  ];

  return (
    <Container className="max-w-[850px]" sx={{ mx: 4, my: 2 }}>
      <Paper elevation={0} className="bg-transparent">
        <Box className="flex flex-col gap-[16px]">
          <div
            style={{
              border: "1px solid lightgray",
              borderLeft: "2px solid rgb(2, 129, 192)",
            }}
          >
            <Typography
              variant="h5"
              className="text-wolfGrey bg-grayscaleIce py-[0.75rem] px-[1rem]"
            >
              Contact Information
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Box className="py-[0.5rem] px-[1.5rem]">
              <Grid container>
                {personalDetailsArray.map((detail, index) => (
                  <DetailRow
                    key={index}
                    label={detail.label}
                    value={detail.value}
                    halfWidth={detail.halfWidth}
                  />
                ))}
                <Grid item xs={12}>
                  <Divider sx={{ my: 3 }} />
                </Grid>
                {addressDetailsArray.map((detail, index) => (
                  <DetailRow
                    key={index}
                    label={detail.label}
                    value={detail.value}
                  />
                ))}
              </Grid>
            </Box>
          </div>
        </Box>
      </Paper>
    </Container>
  );
};

export default AccountDetailsPage;
