import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Person, CreditCard } from "@mui/icons-material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { useNavigate } from "react-router-dom";

function SideBarListItem({ item, isSelected, index }) {
  const navigate = useNavigate();

  const borderRightStyle = isSelected
    ? "4px solid rgb(2 129 192)" // lighterblueblue
    : "2px solid transparent";
  const hoverBorderRightStyle = isSelected
    ? "4px solid rgb(2 129 192)" // lighterblueblue
    : "2px solid #414B56";
  const iconColor = isSelected
    ? "rgba(0, 110, 165)" // blueblue
    : "#414B56"; // wolfGrey
  const marginStyling = index > 0 ? "my-[10px]" : "mb-[10px]";

  return (
    <ListItem
      className={`
        cursor-pointer hover:bg-lightestGrey
        ${marginStyling}`}
      sx={{
        borderRight: borderRightStyle,
        "&:hover": {
          borderRight: hoverBorderRightStyle,
        },
      }}
      onClick={() => navigate(`/account-settings/${item.pageKey}`)}
    >
      <ListItemIcon>
        {item.icon && <item.icon sx={{ color: iconColor, width: "25px" }} />}
      </ListItemIcon>
      <ListItemText
        sx={{
          marginBottom: "2px",
        }}
        primary={item.text}
        primaryTypographyProps={{
          color: iconColor,
          fontSize: "15px",
        }}
      />
    </ListItem>
  );
}

function AccountSettingsSideBar({ page, isMobile }) {
  const navigate = useNavigate();
  const listItems = [
    {
      icon: Person,
      text: "Account Details",
      pageKey: "account-details",
    },
    {
      icon: CreditCard,
      text: "Billing & Shipping Details",
      pageKey: "billing-shipping-details",
    },
    {
      icon: AssignmentOutlinedIcon,
      text: "Tax Exemption Certificates",
      pageKey: "tax-exemption-certificates",
    },
  ];

  if (isMobile) {
    return (
      <FormControl fullWidth sx={{ marginBottom: "2.5rem" }}>
        <Select
          value={page || "account-details"}
          onChange={(e) => navigate(`/account-settings/${e.target.value}`)}
          sx={{ bgcolor: "white" }}
        >
          {listItems.map((item) => (
            <MenuItem key={item.pageKey} value={item.pageKey}>
              <div className="flex items-center gap-[1rem]">
                <item.icon
                  className={`w-[20px] ${
                    page === item.pageKey
                      ? "text-blueblue" // blueblue
                      : "text-wolfGrey" // wolfGrey
                  }`}
                />
                <span
                  className={`mb-[-2px] ${
                    page === item.pageKey
                      ? "text-blueblue" 
                      : "text-wolfGrey"
                  }`}
                >
                  {item.text}
                </span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <List
      sx={{
        bgcolor: "transparent",
        px: 2,
        py: 0,
        width: 350,
        "& .MuiListItemIcon-root": {
          minWidth: 40,
          color: "text.secondary",
        },
      }}
    >
      {listItems.map((item, index) => (
        <SideBarListItem
          key={item.pageKey}
          item={item}
          isSelected={page === item.pageKey}
          index={index}
        />
      ))}
    </List>
  );
}

export default AccountSettingsSideBar;
